import { createUseApi } from '~/1st-api';
import {
  AppAuthClient,
  AppInviteClient,
  AppOrderClient,
  AppRoleClient,
  AppSessionClient,
  AppUserClient,
} from './clients';

export const useApi = createUseApi(() => ({
  auth: new AppAuthClient(),
  invite: new AppInviteClient(),
  order: new AppOrderClient(),
  role: new AppRoleClient(),
  session: new AppSessionClient(),
  user: new AppUserClient(),
}));
