import { CrudRestClient } from '~/1st-crud';
import {
  mapOrder,
  mapOrderRaw,
  Order,
  OrderRaw,
} from '../schema';

export class OrderRestClient extends CrudRestClient<Order, OrderRaw> {
  protected url = '/api/order';

  protected mapModel = mapOrder;

  protected mapModelRaw = mapOrderRaw;
}
