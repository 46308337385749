import { mapOrVoid } from '~/1st-api';

export type Order = {
  id?: string;
  code?: string;
  name?: string;
  phone?: string;
  address?: string;
  comment?: string;
  date?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type OrderRaw = {
  id?: string;
  code?: string;
  name?: string;
  phone?: string;
  address?: string;
  comment?: string;
  date?: string;
  created_at?: string;
  updated_at?: string;
};

export function mapOrder(data: Order): OrderRaw {
  return {
    id: mapOrVoid(data.id, String),
    code: mapOrVoid(data.code, String),
    name: mapOrVoid(data.name, String),
    phone: mapOrVoid(data.phone, String),
    address: mapOrVoid(data.address, String),
    comment: mapOrVoid(data.comment, String),
    date: data.date?.toISOString(),
    created_at: data.createdAt?.toISOString(),
    updated_at: data.updatedAt?.toISOString(),
  };
}

export function mapOrderRaw(dataRaw: OrderRaw): Order {
  return {
    id: mapOrVoid(dataRaw.id, String),
    code: mapOrVoid(dataRaw.code, String),
    name: mapOrVoid(dataRaw.name, String),
    phone: mapOrVoid(dataRaw.phone, String),
    address: mapOrVoid(dataRaw.address, String),
    comment: mapOrVoid(dataRaw.comment, String),
    date: mapOrVoid(dataRaw.date, (date) => new Date(date)),
    createdAt: mapOrVoid(dataRaw.created_at, (date) => new Date(date)),
    updatedAt: mapOrVoid(dataRaw.updated_at, (date) => new Date(date)),
  };
}
