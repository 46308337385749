import { CrudRestClient } from '~/1st-crud';
import {
  mapInvite,
  mapInviteRaw,
  Invite,
  InviteRaw,
} from '../schema';

export class InviteRestClient extends CrudRestClient<Invite, InviteRaw> {
  protected url = '/api/invite';

  protected mapModel = mapInvite;

  protected mapModelRaw = mapInviteRaw;
}
