import { mapOrVoid } from '~/1st-api';

export type Invite = {
  id?: string;
  code?: string;
  used?: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export type InviteRaw = {
  id?: string;
  code?: string;
  used?: number;
  created_at?: string;
  updated_at?: string;
};

export function mapInvite(data: Invite): InviteRaw {
  return {
    id: mapOrVoid(data.id, String),
    code: mapOrVoid(data.code, String),
    used: mapOrVoid(data.used, Number),
    created_at: data.createdAt?.toISOString(),
    updated_at: data.updatedAt?.toISOString(),
  };
}

export function mapInviteRaw(dataRaw: InviteRaw): Invite {
  return {
    id: mapOrVoid(dataRaw.id, String),
    code: mapOrVoid(dataRaw.code, String),
    used: mapOrVoid(dataRaw.used, Number),
    createdAt: mapOrVoid(dataRaw.created_at, (date) => new Date(date)),
    updatedAt: mapOrVoid(dataRaw.updated_at, (date) => new Date(date)),
  };
}
